.reserva-detalhe_origem-destino input
{
    color: #1976D2 !important;
    font-weight: bold; 
    -webkit-text-fill-color: #1976D2 !important;
}

.reserva-detalhe_numero-container span
{
    font-weight: 500;
    color: #282F8A;
}

.reserva-detalhe_btn-rastreamento 
{
    border-width: 2px !important;
    color: #E39F3B !important;
    border-color: #E39F3B !important;
}