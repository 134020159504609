.app-header
{
    background-color: #fff !important;
}

.app-header_app-name
{
    color: #282F8A;
    font-size: 16px !important;
    text-align: left !important;
}

.app-header_toolbar 
{
    min-height: 80px !important;
}

@media (min-width: 600px) {
    .app-header_toolbar 
    {
        padding: 0 !important;
        margin: 0 !important;
    }
}