.app-brand h1 
{
    font-size: 18px;
    color: #282F8A;
    margin-top: -40px;
    margin-bottom: 40px;
}

.app-brand-small h1 
{
    margin-top: -30px;
    font-size: 13px;
}