
.auth-access-token_form
{     
    text-align: center;
}

.auth-access-token_btnEnviar 
{
    margin-top: 20px !important;
    margin-bottom: 10px !important;
}

.auth-access-token_btn-cancelar 
{
    text-decoration: none;
}

.auth-access-token_label-code-sent
{
    margin-bottom: 20px;
    margin-top: -10px;
}

.auth-access-token_label-code-sent p
{
    color: #2F80ED;
    font-size: 11px;
}

.auth-access-token_label-code-sent strong
{
    text-transform: uppercase;
    font-size: 12px;
    margin-top: -20px;
}

