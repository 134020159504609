.app-brand h1 
{
    font-size: 18px;
    color: #282F8A;
    margin-top: -40px;
    margin-bottom: 40px;
}

.app-brand-small h1 
{
    margin-top: -30px;
    font-size: 13px;
}
.novo-usuario_form button[type=submit]
{
    margin-top: 10px;
    margin-bottom: 10px;
}
.novo-usuario-message_container-title 
{
    color: #273270;
    font-size: 14px;
    margin-top: 20px;
}

.novo-usuario-message_container-text 
{
    font-size: 12px;
    line-height: 24px;
    max-width: 300px;
    margin: 30px auto 40px auto;
    font-weight: 500;
}

.auth_form 
{
    text-align: center;
}

.auth_btnEnviar 
{
    margin-top: 17px !important;
    margin-bottom: 30px !important;
}

.auth_btn-novo-usuario 
{
    text-decoration: none;
}


.auth-access-token_form
{     
    text-align: center;
}

.auth-access-token_btnEnviar 
{
    margin-top: 20px !important;
    margin-bottom: 10px !important;
}

.auth-access-token_btn-cancelar 
{
    text-decoration: none;
}

.auth-access-token_label-code-sent
{
    margin-bottom: 20px;
    margin-top: -10px;
}

.auth-access-token_label-code-sent p
{
    color: #2F80ED;
    font-size: 11px;
}

.auth-access-token_label-code-sent strong
{
    text-transform: uppercase;
    font-size: 12px;
    margin-top: -20px;
}


.auth-access-token_btn-resend-access-code 
{
    margin-top: 30px !important;
}

.auth-access-token_timer 
{
    display: block;
    text-align: center;
}
.app-header
{
    background-color: #fff !important;
}

.app-header_app-name
{
    color: #282F8A;
    font-size: 16px !important;
    text-align: left !important;
}

.app-header_toolbar 
{
    min-height: 80px !important;
}

@media (min-width: 600px) {
    .app-header_toolbar 
    {
        padding: 0 !important;
        margin: 0 !important;
    }
}
.card-novidades {
  margin: 0 auto;
}

.card-novidades_text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.slider-card-novidades 
{
    list-style: none;
    padding: 0;
    margin-bottom: 15px;
}

.slider-card-novidades li 
{
    display: none;
}

.slider-card-novidades .visible 
{
    display: block;
}

.slider-card-novidades-bullets
{
    list-style: none; 
    padding: 0;
    text-align: center;
    margin-bottom: 15px;
}

.slider-card-novidades-bullets li
{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 5px;
    display: inline-block;
}

.slider-card-novidades-bullets .active 
{
    background-color: #282F8A;
}

.app-header
{
    background-color: #fff !important;
}

.app-header_app-name
{
    color: #282F8A;
    font-size: 16px !important;
    text-align: left !important;
}

.app-header_toolbar 
{
    min-height: 80px !important;
}
.navio-caminho 
{
    height: 98px;
    background-color: #eef9ff;
    background-repeat: repeat-x;
    background-position: bottom;
    padding: 20px 0;
    box-sizing: border-box;
    -webkit-animation: bgMove 2s infinite ease;
            animation: bgMove 2s infinite ease;
    background-position-x: 20%;
    position: fixed;
    width: 100%;
    z-index: 2;
    top: 80px;
    left: 0;
}


.navio-caminho hr 
{
    background-color: #1962B9;
}

.navio-caminho_chip
{
    position: relative;
}

.navio-caminho_chip::before
{
    content: '';
    width: 0; 
    height: 0; 
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;    
    border-top: 6px solid black;
    position: absolute;
    left: 50%;
    top: 102%;
    margin-left: -6px;
}

.navio-caminho_ship_way 
{
    width: 100%;
    margin-bottom: -10px;
    height: 30px;
    position: relative;
}

.navio-caminho_ship 
{
    margin-top: -10px;
    -webkit-animation: swing 1.5s infinite ease;
            animation: swing 1.5s infinite ease;
    
    position: absolute;
}

.navio-caminho_ship_step1 { left: 0; }
.navio-caminho_ship_step2 { left: 10%; }
.navio-caminho_ship_step3 { left: 20%; }
.navio-caminho_ship_step4 { left: 30%; }
.navio-caminho_ship_step5 { left: 40%; }
.navio-caminho_ship_step6 { right: 35%; }
.navio-caminho_ship_step7 { right: 30%; }
.navio-caminho_ship_step8 { right: 20%; }
.navio-caminho_ship_step9 { right: 10%; }
.navio-caminho_ship_step10 { right: 0%; }



@-webkit-keyframes swing
{
  0%{ -webkit-transform: rotate(-1deg); transform: rotate(-1deg);}
  50%{ -webkit-transform: rotate(4deg); transform: rotate(4deg);}
  100%{ -webkit-transform: rotate(-1deg); transform: rotate(-1deg);}
}



@keyframes swing
{
  0%{ -webkit-transform: rotate(-1deg); transform: rotate(-1deg);}
  50%{ -webkit-transform: rotate(4deg); transform: rotate(4deg);}
  100%{ -webkit-transform: rotate(-1deg); transform: rotate(-1deg);}
}

@-webkit-keyframes bgMove
{
  0%{ background-position-y: 100%; background-position-x: 3%; }
  50%{ background-position-y: 120%; background-position-x: 2%;}
  100%{background-position-y: 100%; background-position-x: 3%;}
}

@keyframes bgMove
{
  0%{ background-position-y: 100%; background-position-x: 3%; }
  50%{ background-position-y: 120%; background-position-x: 2%;}
  100%{background-position-y: 100%; background-position-x: 3%;}
}
.reserva-detalhe_origem-destino input
{
    color: #1976D2 !important;
    font-weight: bold; 
    -webkit-text-fill-color: #1976D2 !important;
}

.reserva-detalhe_numero-container span
{
    font-weight: 500;
    color: #282F8A;
}

.reserva-detalhe_btn-rastreamento 
{
    border-width: 2px !important;
    color: #E39F3B !important;
    border-color: #E39F3B !important;
}
* 
{
    font-family: 'Roboto', sans-serif;
    padding: 0;
    margin: 0;
}



.text-align-center 
{
    text-align: center;
}

.page-title 
{
    font-size: 14px;
    color: #273270;
    margin-bottom: 20px;
}

.text-decoration-none 
{
    text-decoration: none !important;
} 

.page-with-fixed-header 
, .page-with-fixed-header-with-navio
{
    padding-top: 80px;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.page-with-fixed-header-with-navio 
{
    padding-top: 180px;
}

.d-none 
{
    display: none !important;
}

