* 
{
    font-family: 'Roboto', sans-serif;
    padding: 0;
    margin: 0;
}



.text-align-center 
{
    text-align: center;
}

.page-title 
{
    font-size: 14px;
    color: #273270;
    margin-bottom: 20px;
}

.text-decoration-none 
{
    text-decoration: none !important;
} 

.page-with-fixed-header 
, .page-with-fixed-header-with-navio
{
    padding-top: 80px;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.page-with-fixed-header-with-navio 
{
    padding-top: 180px;
}

.d-none 
{
    display: none !important;
}
