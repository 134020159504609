
.auth_form 
{
    text-align: center;
}

.auth_btnEnviar 
{
    margin-top: 17px !important;
    margin-bottom: 30px !important;
}

.auth_btn-novo-usuario 
{
    text-decoration: none;
}
