.navio-caminho 
{
    height: 98px;
    background-color: #eef9ff;
    background-repeat: repeat-x;
    background-position: bottom;
    padding: 20px 0;
    box-sizing: border-box;
    animation: bgMove 2s infinite ease;
    background-position-x: 20%;
    position: fixed;
    width: 100%;
    z-index: 2;
    top: 80px;
    left: 0;
}


.navio-caminho hr 
{
    background-color: #1962B9;
}

.navio-caminho_chip
{
    position: relative;
}

.navio-caminho_chip::before
{
    content: '';
    width: 0; 
    height: 0; 
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;    
    border-top: 6px solid black;
    position: absolute;
    left: 50%;
    top: 102%;
    margin-left: -6px;
}

.navio-caminho_ship_way 
{
    width: 100%;
    margin-bottom: -10px;
    height: 30px;
    position: relative;
}

.navio-caminho_ship 
{
    margin-top: -10px;
    animation: swing 1.5s infinite ease;
    
    position: absolute;
}

.navio-caminho_ship_step1 { left: 0; }
.navio-caminho_ship_step2 { left: 10%; }
.navio-caminho_ship_step3 { left: 20%; }
.navio-caminho_ship_step4 { left: 30%; }
.navio-caminho_ship_step5 { left: 40%; }
.navio-caminho_ship_step6 { right: 35%; }
.navio-caminho_ship_step7 { right: 30%; }
.navio-caminho_ship_step8 { right: 20%; }
.navio-caminho_ship_step9 { right: 10%; }
.navio-caminho_ship_step10 { right: 0%; }



@keyframes swing
{
  0%{ transform: rotate(-1deg);}
  50%{ transform: rotate(4deg);}
  100%{ transform: rotate(-1deg);}
}

@keyframes bgMove
{
  0%{ background-position-y: 100%; background-position-x: 3%; }
  50%{ background-position-y: 120%; background-position-x: 2%;}
  100%{background-position-y: 100%; background-position-x: 3%;}
}