.card-novidades {
  margin: 0 auto;
}

.card-novidades_text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
