.slider-card-novidades 
{
    list-style: none;
    padding: 0;
    margin-bottom: 15px;
}

.slider-card-novidades li 
{
    display: none;
}

.slider-card-novidades .visible 
{
    display: block;
}

.slider-card-novidades-bullets
{
    list-style: none; 
    padding: 0;
    text-align: center;
    margin-bottom: 15px;
}

.slider-card-novidades-bullets li
{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 5px;
    display: inline-block;
}

.slider-card-novidades-bullets .active 
{
    background-color: #282F8A;
}
