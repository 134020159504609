.novo-usuario-message_container-title 
{
    color: #273270;
    font-size: 14px;
    margin-top: 20px;
}

.novo-usuario-message_container-text 
{
    font-size: 12px;
    line-height: 24px;
    max-width: 300px;
    margin: 30px auto 40px auto;
    font-weight: 500;
}